import autobind from 'autobind-decorator'
import isNull from 'lodash/isNull'
import isObjectLike from 'lodash/isObjectLike'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import * as mobx from 'mobx'
import {
  action,
  computed,
  makeObservable,
  observable,
  observe,
  runInAction,
} from 'mobx'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { NativeFile } from '../common/utils'
import AppStore from './AppStore'
import { PrintableStore } from './PrintableStore'
import { getStorage } from './Storage'

export default class BaseStore {
  @observable items = observable.array()
  @observable structure = observable.array()
  @observable access = observable.array()
  @observable item = {}
  @observable filters = observable.map({})
  @observable filtersEnabled = true
  @observable order = null
  @observable page = 1
  @observable total_items = 0

  pathname
  filterTimeout = 0

  printable = new PrintableStore()

  @observable dict_tables = observable.map({})
  dict_queues = []

  constructor() {
    makeObservable(this)
    this.filterJsReduce = this.filterJsReduce.bind(this)
  }

  getDictTable(table, table_id) {
    if (!table_id) {
      return {}
    } else if (
      this.dict_tables.has(table) &&
      this.dict_tables.get(table).has(`${table_id}`)
    ) {
      return this.dict_tables.get(table).get(`${table_id}`) || {}
    } else if (this.addQueue('/' + table + '/' + table_id)) {
      localDB
        .get('/' + table + '/' + table_id)
        .then(d => this.onDictTableLoad(d, table))
    }
    return {}
  }

  onDictTableLoad = (data, table) => {
    data && this.setDictTable(data.item, table)
  }

  @action
  setDictTable(item, table) {
    if (!this.dict_tables.has(table))
      this.dict_tables.set(table, observable.map({}))
    this.removeQueue('/' + table + '/' + item.id)
    this.dict_tables.get(table).set(`${item.id}`, item)
  }

  addQueue(path) {
    const i = this.dict_queues.indexOf(path)
    return i === -1 && this.dict_queues.push(path)
  }

  removeQueue(path) {
    const i = this.dict_queues.indexOf(path)
    i !== -1 && this.dict_queues.splice(i, 1)
  }

  async fetchItem(pathname, defaultData) {
    const data = await this.localGet(pathname)
    await this.setResult(data, defaultData)
    return data
  }

  async fetchMany(silent = false) {
    if (!this.pathname) return
    let params = {}
    params.page = this.page
    this.filtersEnabled &&
      mobx
        .entries(this.filters)
        .filter(BaseStore.validFilters)
        .map(([key, value]) => (params[`filter[${key}]`] = value))
    if (this.order) params.order = this.order
    const data = await this.localGet(
      this.pathname,
      params,
      this.order || 'rec_date desc',
      silent,
    )
    this.setResult(data)
    return data
  }

  async localGet(...args) {
    return await localDB.get(...args)
  }

  static validFilters(filter) {
    const value = filter[1]
    return (
      !isNull(value) &&
      (!isString(value) || value.length > 0) &&
      !isObjectLike(value) &&
      !isUndefined(value) &&
      value !== false
    )
  }

  get jsonFilters() {
    return this.filtersEnabled
      ? mobx
          .entries(this.filters)
          .filter(BaseStore.validFilters)
          .reduce(BaseStore.reduceFilters, {})
      : {}
  }

  static reduceFilters(f, [key, value]) {
    f[key] = value
    return f
  }

  async fetchData({ url }, silent = false) {
    this.pathname = url
    try {
      return await this.fetchMany(silent)
    } finally {
      this.enableFiltersObserver()
    }
  }

  @autobind
  filtersObserver() {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout)
      this.filterTimeout = 0
    }
    this.filterTimeout = setTimeout(() => this.fetchMany(), 500)
  }

  setResult(data, defaultData) {
    if (!data) return
    if (data.structure)
      this.setStructure(data.structure, data.item, defaultData)
    if (data.access) this.setAccess(data.access)
    if (data.list) {
      this.setData(data.list, data.count)
      if (this.page > this.pagesCount) {
        this.setPage(1)
        return this.fetchMany()
      }
    } else {
      data.item ? this.setSingle(data.item) : this.setSingle(data)
    }
  }

  async putFiles(pathname, item) {
    await Promise.all(
      Object.keys(item)
        .filter(k => k.startsWith('file_'))
        .map(async k => {
          if (
            (typeof File !== 'undefined' && item[k] instanceof File) ||
            item[k] instanceof NativeFile
          ) {
            let form = new FormData()
            form.append('file', item[k])
            let { data } = await requester.put(`${pathname}`, form)
            item[k] = data.link
          }
        }),
    )
  }

  async postData(pathname, item = this.item) {
    try {
      this.checkRequiredFields(item)
      await this.putFiles(pathname, item)
      if (item.data && typeof item.data === 'object')
        await this.putFiles(pathname, item.data)
      let data = await localDB.post(`${pathname}`, item)
      if (data.id && Array.isArray(data.id) && data.id.length === 1) {
        data.id = data.id[0]
      }
      runInAction(() => (this.item.id = data.id))
      this.saveSuccessMessage()
      return data
    } catch (e) {
      this.catchPostData(e)
      throw e
    }
  }

  checkRequiredFields(item) {
    for (const s of this.structure) {
      if (
        s.required &&
        (item[s.name] === null || typeof item[s.name] === 'undefined')
      ) {
        throw new Error('Заполните поле ' + s.display_name)
      }
    }
  }

  saveSuccessMessage() {
    AppStore.showSuccess('Данные сохранены')
  }

  catchPostData(e) {
    !e.result && AppStore.showWarning(e.message)
  }

  async deleteData(pathname, remote = false) {
    if (remote) return await requester.delete(pathname)
    return await localDB.delete(pathname)
  }

  @action
  setData(data, count) {
    this.items.replace(data)
    this.total_items = count || this.items.length
  }

  @action
  setSingle(item) {
    this.item = item || {}
  }

  enableFiltersObserver = () => {
    if (!this.filtersDisposer) {
      this.filtersDisposer = observe(this.filters, this.filtersObserver)
    }
  }

  disposeFiltersDispose = () => {
    if (this.filtersDisposer) {
      this.filtersDisposer()
      this.filtersDisposer = null
    }
  }

  clearItems() {
    this.disposeFiltersDispose()
    this.items.clear()
    this.structure.clear()
    this.clearItem()
  }

  @action
  clearItem() {
    //Don't change, no form checks for item === null
    this.item = {}
    this.dict_tables.clear()
    this.printable.clear()
  }

  clearFilter() {
    this.filters.clear()
  }

  @action
  clearOrder() {
    this.order = null
  }

  @action
  setStructure(structure, item, defaultData) {
    if (defaultData) {
      for (const s of structure) {
        if (!defaultData[s.name]) continue
        s.default = isObjectLike(defaultData)
          ? { ...s.default, ...defaultData[s.name] }
          : defaultData[s.name]
      }
    }
    if (item) {
      for (let s of structure) {
        if (typeof s.default === 'undefined') continue
        if (
          typeof item[s.name] === 'undefined' ||
          (s.required && item[s.name] === null) ||
          !item.id
        ) {
          item[s.name] = s.default
        }
        if (
          item[s.name] &&
          typeof s.default === 'object' &&
          !Array.isArray(s.default)
        ) {
          for (let d in s.default) {
            if (
              s.default.hasOwnProperty(d) &&
              typeof item[s.name][d] === 'undefined'
            ) {
              item[s.name][d] = s.default[d]
            }
          }
        }
      }
    }
    this.structure.replace(structure)
  }

  mapStructureReadOnly(s) {
    return { ...s, read_only: true }
  }

  @action
  setAccess(access) {
    this.access.replace([...access])
  }

  canSave() {
    return this.item.id
      ? this.access.includes('edit')
      : this.access.includes('create')
  }

  @computed get can_save() {
    return this.canSave()
  }

  canDelete() {
    return this.item.id ? this.access.includes('delete') : false
  }

  @computed get can_delete() {
    return this.canDelete()
  }

  isReadOnly() {
    return !this.can_save
  }

  @computed get is_read_only() {
    return this.isReadOnly()
  }

  filterDefaults() {
    return {}
  }

  get filter_defaults() {
    return this.filterDefaults()
  }

  async loadFilters(url) {
    try {
      const stored_filters = await getStorage().load({
        key: 'filters',
        id: url,
      })
      return isObjectLike(stored_filters) ? stored_filters : {}
    } catch {}
    return {}
  }

  async restoreFilters(url, defaults) {
    let filters = {
      ...this.filter_defaults,
      ...(await this.loadFilters(url)),
      ...defaults,
    }
    isObjectLike(filters) && this.applyFilter(filters)
  }

  @action
  applyFilter(filters) {
    isObjectLike(filters) && this.filters.replace(filters)
  }

  saveFilters(url) {
    getStorage().save({ key: 'filters', id: url, data: this.filters_js })
  }

  @action.bound
  setFilter(key, value) {
    if (isObjectLike(key)) this.filters.replace(key)
    else this.filters.set(key, value)
  }

  @action.bound
  toggleFiltersEnabled() {
    this.filtersEnabled = !this.filtersEnabled
  }

  @action
  setPage(page) {
    this.page = page
  }

  @computed
  get pagesCount() {
    if (this.total_items > 40) return Math.ceil(this.total_items / 40)
    return 1
  }

  @computed
  get filtersActive() {
    return (
      this.filtersEnabled &&
      mobx.entries(this.filters).filter(BaseStore.validFilters).length > 0
    )
  }

  filter_keys = ['start_from', 'end_to', 'by_date', 'branch_id']

  add_filter_keys(...keys) {
    for (const key of keys)
      !this.filter_keys.includes(key) && this.filter_keys.push(key)
  }

  remove_filter_keys(...keys) {
    for (const key of keys)
      this.filter_keys.includes(key) &&
        this.filter_keys.splice(this.filter_keys.indexOf(key), 1)
  }

  get should_save_filter_keys() {
    return this.filter_keys
  }

  @computed
  get filters_js() {
    return mobx.entries(this.filters).reduce(this.filterJsReduce, {})
  }

  getStructure() {
    return (this.structure || []).filter(item => !item.exclude)
  }

  @computed get item_structure() {
    return this.getStructure()
  }

  @computed get visible_structure() {
    return this.item_structure.filter(item => !item.hide_edit)
  }

  @computed get hide_structure() {
    return this.item_structure.filter(item => item.hide_edit)
  }

  filterJsReduce(f, [k, value]) {
    if (!this.should_save_filter_keys.includes(k)) return f
    if (BaseStore.validFilters([k, value])) f[k] = value
    return f
  }
}
