import { action, computed, makeObservable, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { __DOMAIN__ } from '../common/domain'
import requester from '../common/requester'

// noinspection SpellCheckingInspection
const STATIC_LANGUAGES = [
  { code: 'ru', name: 'Русский' },
  { code: 'tr', name: 'Türkçe' },
]

class Language {
  @persist @observable code = 'ru'
  @persist @observable name = 'Русский'
}

class SettingsStore {
  @persist @observable company = ''
  @persist @observable domain = __DOMAIN__
  @persist @observable language = 'ru'
  @persist('list', Language) @observable languages = observable.array()

  constructor() {
    makeObservable(this)
  }

  @computed get language_options() {
    return this.languages.map(l => {
      return { value: l.code, text: l.name }
    })
  }

  @action
  setDomain = (domain = __DOMAIN__) => (this.domain = domain)

  @action
  setLanguage = (language = 'ru') => (this.language = language)

  @action
  setLanguages = languages => {
    this.languages = languages
    this.languages.every(l => l.code !== this.language) &&
      this.setLanguage(this.languages[0].code)
  }

  fetchLanguages(native = false, toiboss = false) {
    if (native && !toiboss && !this.company) {
      this.setLanguages(STATIC_LANGUAGES)
      return
    }
    requester
      .get('/localizations/languages/list')
      .then(({ data }) => {
        this.setLanguages(data.list)
      })
      .catch(() => {
        this.languages.length === 0 && this.setLanguages(STATIC_LANGUAGES)
      })
  }
}

const store = new SettingsStore()
export default store
