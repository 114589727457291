import isUndefined from 'lodash/isUndefined'
import * as mobx from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import { getModelValue, guid, is_my_branch } from '../common/utils'
import AppStore from './AppStore'
import BaseStore from './BaseStore'

const INVOICE_STATUS_ORDERS = {
  draft: 0,
  receive: 1,
  received: 2,
}

export class InvoiceStore extends BaseStore {
  @observable dict_branches = observable.map({})
  @observable dict_contractors = observable.map({})
  @observable edit_product = null
  @observable search_identity = ''

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get is_loading() {
    return AppStore.isBusy
  }

  @action setLoadingCounter(increase = true) {
    AppStore.setBusyState(increase)
  }

  @computed get has_tara_quantity() {
    return this.item.data?.products.some(p => p.tara_quantity) || false
  }

  @computed get has_identity() {
    return this.item.data?.products.some(p => p.identity) || false
  }

  @computed get has_tara_identity() {
    return this.item.data?.products.some(p => p.tara_identity) || false
  }

  @computed get tara_total_quantity() {
    return (
      this.item.data?.products
        .reduce((a, p) => {
          const q = parseFloat(p.tara_quantity)
          return isFinite(q) ? a + q : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  @computed get tara_total_weight() {
    return (
      this.item.data?.products
        .reduce((a, p) => {
          const q = parseFloat(p.tara_quantity)
          const w = parseFloat(p.tara_weight)
          return isFinite(q) && isFinite(w) ? a + q * w : a
        }, 0)
        .toFixed(3) || 0
    )
  }

  setData(data, count) {
    for (let item of data) {
      if (
        item.branch_id &&
        item.branch &&
        !this.dict_branches.has(`${item.branch_id}`)
      ) {
        this.dict_branches.set(`${item.branch_id}`, item.branch)
      }
      if (
        item.contractor_id &&
        item.contractor &&
        !this.dict_contractors.has(`${item.contractor_id}`)
      ) {
        this.dict_contractors.set(`${item.contractor_id}`, item.contractor)
      }
    }
    super.setData(data, count)
  }

  clearItems() {
    super.clearItems()
    this.dict_branches?.clear()
    this.dict_contractors?.clear()
  }

  clearItem() {
    super.clearItem()
    this.edit_product = null
    this.prices = null
  }

  getBranchName(branch_id) {
    if (!branch_id) {
      return ''
    } else if (this.dict_branches.has(`${branch_id}`)) {
      return this.dict_branches.get(`${branch_id}`).name
    } else if (this.addQueue('/branchs/' + branch_id)) {
      localDB.get('/branchs/' + branch_id).then(this.onBranchLoad)
    }
    return ''
  }

  onBranchLoad = data => {
    data && this.setBranch(data.item)
  }

  @action
  setBranch(branch) {
    this.removeQueue('/branchs/' + branch.id)
    this.dict_branches.set(`${branch.id}`, branch)
  }

  getContractorName(contractor_id) {
    if (!contractor_id) {
      return ''
    } else if (this.dict_contractors.has(`${contractor_id}`)) {
      return this.dict_contractors.get(`${contractor_id}`).name
    } else if (this.addQueue('/contractors/' + contractor_id)) {
      localDB.get('/contractors/' + contractor_id).then(this.onContractorLoad)
    }
    return ''
  }

  onContractorLoad = data => {
    data && this.setContractor(data.item)
  }

  @action
  setContractor(contractor) {
    this.removeQueue('/contractors/' + contractor.id)
    this.dict_contractors.set(`${contractor.id}`, contractor)
  }

  async getPricesMap() {
    if (this.prices) return this.prices
    try {
      const { list: templates_list } = await localDB.get('/templates', {
        'filter[temp_type]': 'price',
      })
      this.prices = templates_list.reduce((templates_map, template) => {
        if (template.temp_type !== 'price') return templates_map
        templates_map[template.id] = template
        templates_map[template.id].data.prices = template.data.prices.reduce(
          (template_prices, price) => {
            if (!price.hasOwnProperty('branch_id'))
              return [...template_prices, price]
            return [...template_prices, ...price.prices]
          },
          [],
        )
        return templates_map
      }, {})
    } catch (e) {}
    return this.prices || {}
  }

  @action setSearchIdentity = identity => (this.search_identity = identity)

  async onReadScan() {
    const identity = this.search_identity.trim()
    if (!identity) return
    this.setLoadingCounter()
    try {
      const data = await localDB.get('/nomenclatures', {
        'filter[identity_search]': identity,
      })
      const items = await Promise.all(
        data.list
          .filter(n => n.identity.split(',').includes(identity))
          .map(n => localDB.get('/nomenclatures/' + n.id)),
      )
      const nomenclatures = items.map(i => i?.item).filter(Boolean)
      if (nomenclatures.length === 0)
        return AppStore.showWarning('Номенклатура не найдена')
      await this.addProducts(nomenclatures)
    } finally {
      this.setLoadingCounter(false)
    }
  }

  addProducts = async nomenclatures => {
    this.setLoadingCounter()
    try {
      if (!this.item.data || nomenclatures.length === 0) return
      let identity = guid().slice(-8)
      if (
        /^\d+$/.test(this.item.incoming_id || '') &&
        this.item.incoming_date
      ) {
        identity =
          moment(this.item.incoming_date).format('YYMMDD') +
          (this.item.incoming_id || '').padStart(2, '0')
      }
      let prices = await this.getPricesMap()
      let products = []
      for (const nomenclature of nomenclatures) {
        let product = {
          uuid: guid(),
          nomenclature_id: nomenclature.id,
          nomenclature: nomenclature,
          total_quantity: null,
          tara_quantity: null,
          tara_weight: null,
          quantity: null,
          price: null,
          identity,
          tara_identity: null,
          due_date: null,
          data: {},
          decider: null,
          prices: [],
          editor: nomenclature.editor || nomenclature.category?.editor || '',
        }
        const { templates } = nomenclature.data
        if (!templates || templates.length < 1) {
          products.push(product)
          continue
        }
        if (prices[templates[0]])
          this.setPrices(product, prices[templates[0]].data)
        products.push(product)
      }
      this.extendProducts(products)
    } finally {
      this.setLoadingCounter(false)
    }
  }

  @action
  extendProducts(products) {
    if (!this.item.data) return
    this.item.data.products.replace([
      ...products.map(p => ({
        ...p,
        uuid: guid(),
        rec_date: moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
      })),
      ...this.item.data.products,
    ])
    products.length === 1 && this.toggleEditor(this.item.data.products[0])
  }

  @action.bound
  copyEditProduct() {
    if (this.edit_product) {
      const product = JSON.parse(JSON.stringify(this.edit_product))
      product.total_quantity = null
      product.quantity = null
      this.extendProducts([product])
    }
  }

  @action
  toggleEditor(product) {
    this.edit_product = product ? product : null
  }

  @action.bound
  removeEditProduct() {
    const product = this.edit_product
    this.toggleEditor()
    this.removeProduct(product)
  }

  product_amount = (item, product) => {
    const { quantity = 0, price = 0 } = product
    const amount = quantity * price
    const discount = +getModelValue(item, 'discount', 0)
    return isFinite(discount) ? amount * (1 - discount / 100) : amount
  }

  total_price(product) {
    return this.product_amount(this.item, product)
  }

  @action
  setPrice(product) {
    if (!product.data) return
    if (
      product.prices &&
      product.prices.length > 0 &&
      product.data.price_type
    ) {
      let price = product.prices.find(p => p.name === product.data.price_type)
      if (price) {
        let multiplier = 1
        if (product.decider) {
          multiplier = parseFloat(product.data[product.decider] || '0')
        }
        product.price = parseFloat(
          (parseFloat(price.price) * multiplier).toFixed(4),
        )
      }
      if (isNaN(product.price)) {
        product.price = null
      }
    } else {
      product.price = null
    }
  }

  setProductPrices(product, prices) {
    const { templates } = product.nomenclature.data
    if (!templates || templates.length < 1) return
    if (prices[templates[0]]) this.setPrices(product, prices[templates[0]].data)
  }

  setPrices(product, data) {
    let decider = data.decider || null
    if (decider && !product.data[decider]) {
      product.data[decider] = null
    }
    product.decider = decider
    product.prices = data.prices
  }

  @action
  removeProduct(product) {
    const { item } = this
    if (item && item.data) {
      item.data.products.remove(product)
    }
  }

  @action
  setInvoiceStatus(status) {
    this.item.invoice_status = status
  }

  @computed get can_add_product() {
    const { item, access } = this
    const { invoice_status, data } = item
    return (
      !!data &&
      access.includes('edit') &&
      ['draft', 'continuous', 'receive'].includes(invoice_status)
    )
  }

  @computed
  get can_continuous() {
    const { item } = this
    return this.can_add_product && !!item.id && item.invoice_status === 'draft'
  }

  @computed
  get can_receive() {
    const { item } = this
    return this.can_add_product && !!item.id && item.data.products.length > 0
  }

  async fetchMany() {
    if (!this.pathname) return
    let params = {}
    params.page = this.page
    if (this.filtersEnabled) {
      mobx
        .entries(this.filters)
        .filter(BaseStore.validFilters)
        .map(([key, value]) => (params[`filter[${key}]`] = value))
    }
    if (this.order) params.order = this.order
    const data = await localDB.get(this.pathname, params, this.orderInvoices)
    this.setResult(data)
    return data
  }

  orderInvoices = (i1, i2) => {
    return (
      INVOICE_STATUS_ORDERS[i1.invoice_status] -
      INVOICE_STATUS_ORDERS[i2.invoice_status]
    )
  }

  async fetchItem(pathname) {
    this.setLoadingCounter()
    try {
      let response = await localDB.get(pathname)
      let { item } = response
      let { data, invoice_status = 'draft' } = item
      if (!data) item.data = { products: [] }
      else if (!data.products) data.products = []
      let prices = {}
      if (invoice_status !== 'received' && data.products.length > 0)
        prices = await this.getPricesMap()
      for (let product of data.products) {
        product.uuid = product.uuid || guid()
        product.decider = null
        product.prices = null
        product.identity = product.identity || null
        product.tara_identity = product.tara_identity || null
        product.pid_identity = product.pid_identity || []

        let { nomenclature, nomenclature_id } = product
        if (!nomenclature) {
          const { item: nomenclature_item } = await localDB.get(
            '/nomenclatures/' + nomenclature_id,
          )
          nomenclature = nomenclature_item
        }
        product.editor =
          nomenclature.editor || nomenclature.category?.editor || null
        this.setProductEditor(product, nomenclature, product.editor, prices)
      }
      this.setResult(response)
      return response
    } finally {
      this.setLoadingCounter(false)
    }
  }

  setSingle(item) {
    if (
      !is_my_branch(AppStore.user_info, item.branch_id) ||
      item.invoice_status === 'received'
    ) {
      super.setAccess(['view'])
    }
    return super.setSingle(item)
  }

  setProductEditor(product, nomenclature, editor, prices) {
    product.nomenclature = nomenclature
    product.editor = editor
    this.setProductPrices(product, prices)
  }

  @computed
  get products_total_amount() {
    return parseFloat(this.calcTotal(this.item))
  }

  @computed
  get gross_weight_by_scales() {
    return (
      this.item.data.loaded_weight_transport -
      this.item.data.empty_weight_transport
    )
  }

  @computed
  get difference_net() {
    return this.item.data.net_weight_invoice - this.item.data.net_weight_receipt
  }

  @computed
  get difference_net_percent() {
    return (this.difference_net / this.item.data.net_weight_invoice) * 100
  }

  @computed
  get difference_gross() {
    return this.gross_weight_by_scales - this.item.data.gross_weight_receipt
  }

  @computed
  get difference_gross_percent() {
    return (this.difference_gross / this.gross_weight_by_scales) * 100
  }

  @computed
  get difference_gross_net() {
    return (
      this.item.data.gross_weight_receipt - this.item.data.net_weight_receipt
    )
  }

  calcTotal(item) {
    const { data, total_amount, invoice_status } = item
    if (invoice_status === 'received' && !isUndefined(total_amount))
      return total_amount || 0
    let sum = 0
    if (data)
      sum = data.products.reduce(
        (a, p) => a + this.product_amount(item, p),
        sum,
      )
    return sum.toFixed(4)
  }

  catchPostData(e) {
    if (this.item.invoice_status === 'receive') {
      this.setInvoiceStatus('draft')
    }
    super.catchPostData(e)
  }

  async postData(pathname, item = this.item) {
    this.setLoadingCounter()
    try {
      this.item.data.gross_weight_by_scales = this.gross_weight_by_scales
      this.item.data.difference_net = this.difference_net
      this.item.data.difference_net_percent = this.difference_net_percent
      this.item.data.difference_gross = this.difference_gross
      this.item.data.difference_gross_percent = this.difference_gross_percent
      this.item.data.difference_gross_net = this.difference_gross_net

      for (const p of item.data.products) {
        await this.putFiles(pathname, p)
        await this.putFiles(pathname, p.data)
      }
      const data = await super.postData(pathname, item)
      if (
        this.item.invoice_status === 'receive' &&
        isFinite(parseInt(pathname.split('/')[2]))
      ) {
        await this.fetchItem(pathname)
      }
      return data
    } catch (e) {
      this.catchPostData(e)
      throw e
    } finally {
      this.setLoadingCounter(false)
    }
  }

  saveSuccessMessage() {
    const { invoice_status: is } = this.item
    if (is === 'receive') {
      AppStore.showSuccess('Принят')
    } else {
      super.saveSuccessMessage()
    }
  }

  @computed
  get can_print_invoice() {
    const { invoice_status, data } = this.item
    return invoice_status === 'received' && data.products.length > 0
  }

  @computed
  get invoice_printable_data() {
    const {
      id,
      branch,
      data,
      contractor,
      currency,
      incoming_id,
      incoming_date,
    } = this.item
    const { products = [] } = data || {}
    let receiver = ''
    if (branch) {
      receiver = branch.name
      if (branch.data) {
        const address = [branch.data.region, branch.data.address]
          .filter(i => i)
          .join(', ')
        receiver += address ? `. ${address}` : ''
      }
    }
    const today = moment()
    return {
      sender: contractor ? `${contractor.name} ИНН: ${contractor.inn}` : '',
      receiver: receiver,
      currency: currency ? currency.name : '',
      incoming_id: incoming_id || '',
      incoming_date: incoming_date
        ? moment(incoming_date).format('DD-MM-YYYY')
        : '',
      by: '________________________________________________________________________________',
      power_of_attorney:
        '________________________________________________________________________________',
      invoice: id,
      account:
        '________________________________________________________________________________',
      date: today.format('«DD» MMMM YYYY'),
      total: this.products_total_amount,
      Products: products
        .reduce(this.invoiceProductsReduce, [])
        .map(this.invoiceProductsMap),
    }
  }

  invoiceProductsReduce = (products, p) => {
    const { discount } = this.item
    let total = this.product_amount(this.item, p)
    let { nomenclature_id, nomenclature = {}, quantity, price } = p
    const price_for_unit = this.toFloat(price)
    let product = products.find(
      p2 =>
        p2.nomenclature_id === nomenclature_id &&
        p2.price_for_unit === price_for_unit,
    )
    if (!product) {
      let { name, unit = {} } = nomenclature
      let { unit_name, short } = unit
      products.push({
        nomenclature_id,
        name,
        unit: short || unit_name || '',
        quantity: this.toFloat(quantity),
        price_for_unit,
        discount: this.toFloat(discount),
        price_total: this.toFloat(total),
        count: 1,
      })
    } else {
      product.quantity += this.toFloat(quantity)
      product.price_total += this.toFloat(total)
      product.count++
    }
    return products
  }

  invoiceProductsMap = ({
    quantity,
    price_for_unit,
    discount,
    price_total,
    ...p
  }) => ({
    ...p,
    quantity: quantity.toFixed(3),
    price_for_unit: price_for_unit.toFixed(2),
    discount: discount.toFixed(2),
    price_total: price_total.toFixed(2),
  })

  toFloat(num) {
    return (num && !isNaN(num) && parseFloat(num)) || 0
  }
}

const store = new InvoiceStore()
export default store
