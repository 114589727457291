import axios from 'axios'
import * as base64 from 'base-64'
import SettingsStore from '../store/SettingsStore'
import { __DOMAIN__ } from './domain'
import { __HOST__ } from './host'
import { __WEB__ } from './is-web'

export const APP_HOST =
  process.env.NODE_ENV === 'production'
    ? () =>
        !stores.SettingsStore.company
          ? __HOST__
          : `${stores.SettingsStore.company}.${
              stores.SettingsStore.domain || __DOMAIN__
            }`
    : () => __HOST__

// noinspection HttpUrlsUsage
export const WEB_URL =
  process.env.NODE_ENV === 'production'
    ? () => `https://${APP_HOST()}`
    : () => `http://${__HOST__}`

export function API_URL() {
  return `${WEB_URL()}/api/v1`
}

export const __WS_HOST__ = __HOST__.replace('8000', '8080')

export const WS_URL =
  process.env.NODE_ENV === 'production'
    ? store => `wss://web:${store.AppStore.token}@${APP_HOST()}`
    : store => `ws://web:${store.AppStore.token}@${__WS_HOST__}`

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export function isWeb() {
  return __WEB__ === true
}

export function isNativeProduction() {
  return !isWeb() && isProduction()
}

let stores

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const { AppStore } = stores
    // noinspection JSUnresolvedVariable
    if (!config.silent && !config.headers.silent) AppStore.setBusyState(true)
    delete config.headers.silent
    if (AppStore.token)
      config.headers.common.Authorization =
        'Basic ' + base64.encode('web:' + AppStore.token)
    config.headers.common['X-Language'] = SettingsStore.language
    return config
  },
  function (error) {
    const { AppStore } = stores
    if (!error.silent) {
      AppStore.setBusyState(false)
    }
    AppStore.showError(error.message || error)
    return Promise.reject(error)
  },
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    const { AppStore } = stores
    if (!response.config.url.includes('poll') && !response.data.job_id) {
      AppStore.setBusyState(false)
    } else if (response.data.status > 0) {
      // noinspection JSUnresolvedVariable
      if (!response.config.silent) {
        AppStore.setBusyState(false)
      }
      if (response.data.status === 2) {
        console.warn(response)
        AppStore.showError(response.data.message)
        return Promise.reject(response.data)
      }
    }
    if (response.data.result < 0) {
      console.warn(response)
      AppStore.showError(response.data.message)
      return Promise.reject(response.data)
    } else if (response.data.result > 0) {
      console.warn(response)
      if (AppStore.authenticated && [1, 5].includes(response.data.result)) {
        AppStore.logOut()
      }
      AppStore.showWarning(response.data.message)
      return Promise.reject(response.data)
    }
    return response
  },
  function (error) {
    console.warn(error)
    const { AppStore } = stores
    if (!error.silent && (!error.config || !error.config.silent)) {
      AppStore.setBusyState(false)
    }
    AppStore.showError(error.message || error)
    return Promise.reject(error)
  },
)

export function initialize(store) {
  stores = store
}
