import axios from 'axios'
import { changeLanguage, use } from 'i18next'
import HttpApi from 'i18next-http-backend'
import isObjectLike from 'lodash/isObjectLike'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { initReactI18next } from 'react-i18next'
import ru from '../common/translate/ru.json'
import tr from '../common/translate/tr.json'
import SpinnerScreen from './components/SpinnerScreen'
import { API_URL } from './settings'
import storage from './storage'
import useStore from './useStore'

const localizations = { ru, tr }

async function fetchLocalizations(url) {
  const [namespace, language] = url.split('/').slice(-2)
  const static_ns = localizations[language] || {}
  if (['login', 'common'].includes(namespace))
    return { ...static_ns[namespace] }
  try {
    const { data } = await axios.get(url)
    delete data.result
    await storage.save({
      key: 'Localizations',
      id: `${language}/${namespace}`,
      data,
    })
    return { ...static_ns[namespace], ...data }
  } catch {}
  try {
    const data = await storage.load({
      key: 'Localizations',
      id: `${language}/${namespace}`,
    })
    if (data && isObjectLike(data)) {
      return { ...static_ns[namespace], ...data }
    }
  } catch {}
  return { ...static_ns[namespace] }
}

async function i18n(language, languages) {
  moment.locale(language)
  await use(HttpApi)
    .use(initReactI18next)
    .init({
      lng: language,
      supportedLngs: languages.map(l => l.code),
      defaultNS: 'common',
      ns: ['login', 'common'],
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${API_URL()}/localizations/{{ns}}/{{lng}}`,
        async request(options, url, payload, callback) {
          callback(null, {
            data: await fetchLocalizations(url),
            status: 200,
          })
        },
      },
      compatibilityJSON: 'v3',
      react: {
        useSuspense: false,
      },
    })
}

export const LanguageProvider = observer(function LanguageProvider({
  children,
  native = false,
  toiboss = false,
}) {
  const store = useStore()
  const { language, languages } = store.SettingsStore
  const [ready, setReady] = useState(false)

  useEffect(
    () => store.SettingsStore.fetchLanguages(native, toiboss),
    [store, native, toiboss],
  )

  const has_languages = languages.length > 0
  useEffect(() => {
    let active = true
    has_languages &&
      i18n(store.SettingsStore.language, store.SettingsStore.languages).finally(
        () => active && setReady(true),
      )
    return () => {
      active = false
    }
  }, [has_languages, store])

  useEffect(() => {
    ready && moment.locale(language)
    ready && changeLanguage(language)
  }, [ready, language])

  return ready ? <>{children}</> : <SpinnerScreen />
})
