import { reaction } from 'mobx'
import React, { useEffect } from 'react'
import BaseStore from '../../store/BaseStore'
import { getModelValue } from '../utils'

function ToolsReaction({ expression, effect, children }) {
  useEffect(() => reaction(expression, effect), [expression, effect])
  return <>{children}</>
}

export default class FormBase extends React.Component {
  tools = []
  buttons = []
  editFilter = [
    'rec_date',
    'rec_user',
    'is_deleted',
    'id',
    'data',
    'version',
    'upd_date',
    'upd_user',
    'remote_id',
  ]
  state = { modalDelete: false }

  constructor(props) {
    super(props)
    this.initStore()

    this.mapTools = this.mapTools.bind(this)
    this.mapButtons = this.mapButtons.bind(this)
    this.initButtons = this.initButtons.bind(this)
    this.fetchItem = this.fetchItem.bind(this)
    this.postData = this.postData.bind(this)
    this.goBack = this.goBack.bind(this)
    this.showDeleteModal = this.showDeleteModal.bind(this)
    this.hideDeleteModal = this.hideDeleteModal.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.filterEdits = this.filterEdits.bind(this)
    this.mapToReadOnly = this.mapToReadOnly.bind(this)
    this.renderFieldItem = this.renderFieldItem.bind(this)

    this.initButtons()
  }

  get store_class() {
    return BaseStore
  }

  /**
   * Store instance from global stores
   * @returns {BaseStore|null}
   */
  get store_instance() {
    return null
  }

  get editor_path() {
    const { match } = this.props
    const { url = '' } = match
    return url.split('/').slice(0, -1).join('/') + '#edit'
  }

  get editors() {
    const { store } = this.props
    return store.EditorStore.editors
  }

  initStore(StoreClass = this.store_class, component = this.editor_path) {
    let store = this.store_instance
    if (!store) {
      const store_key = component || this.editor_path
      store = this.editors.get(store_key) || new StoreClass()
      this.editors.set(store_key, store)
    }
    this.store = store
  }

  get title() {
    return this.getTitle()
  }

  getTitle() {
    const { name, store, match } = this.props
    const { item } = this.store
    const component = match.url.split('/')[1]
    let item_name = this.getT(item)
    if (item_name) return item_name

    let menu = store.MenuStore.menu_names[component]
    let menu_name = this.getT(menu)
    return name || menu_name || ' '
  }

  getT(item, key = 'name') {
    if (!item) return null
    const data = item.data || {}
    return (
      data[key + '_' + this.props.store.SettingsStore.language] || item[key]
    )
  }

  componentDidMount() {
    this.fetchItem()
  }

  async fetchItem() {
    if (this.store) {
      try {
        await this.store.fetchItem(this.props.match.url, this.props.defaultData)
      } catch (e) {
        if (e && e.result === 404) {
          this.setState({ notFound: true })
        } else {
          console.log(e)
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.url &&
      this.props.match.url &&
      prevProps.match.url !== this.props.match.url
    ) {
      this.fetchItem()
    }
  }

  initButtons(no_delete = false, no_save = false) {
    this.tools = []
    this.buttons = []
    if (!no_delete && this.store.canDelete()) {
      this.buttons.push({
        color: 'danger',
        action: this.showDeleteModal,
        title: 'button.delete',
      })
    }
    if (!no_save && !this.store.isReadOnly()) {
      this.buttons.push({
        color: 'success',
        action: this.postData,
        title: 'button.save',
      })
    }
  }
  t = (key, options) => {
    if (this.props.t) return this.props.t(key, options)
    return key
  }
  tCommon = key => {
    if (this.props.t) {
      return this.props.t(key, { ns: 'common' })
    }
    return key
  }
  componentWillUnmount() {
    this.store && this.store.clearItem()
  }

  showDeleteModal() {
    this.setState({ modalDelete: true })
  }

  hideDeleteModal() {
    this.setState({ modalDelete: false })
  }

  renderForm(tools, fields, buttons, absolute_view) {
    return JSON.stringify([tools, fields, buttons, absolute_view])
  }

  renderFields(item, visible_structure, hide_structure) {
    return visible_structure.reduce(
      (fields, s, i) =>
        s.type !== 'BLOB' &&
        (this.editFilter
          ? !this.editFilter.includes(s.name)
          : !['id', 'rec_date'].includes(s.name))
          ? [
              ...fields,
              this.renderFieldItem(
                item,
                s,
                i,
                this.store.is_read_only || s.read_only,
              ),
            ]
          : fields,
      [],
    )
  }

  get item_data() {
    const item_data = getModelValue(this.store.item, 'data')
    if (item_data && !item_data.hasOwnProperty('model_parent_data_item')) {
      const { item } = this.store
      Object.defineProperty(item_data, 'model_parent_data_item', {
        configurable: false,
        enumerable: false,
        get() {
          return item
        },
      })
    }
    return item_data
  }

  renderFieldItem(item, s, i, readOnly) {
    return s.name
  }

  renderVisibleFields(item) {
    return null
  }

  renderHideFields(item) {
    return null
  }

  absoluteForm() {
    return null
  }

  mapTools(tool) {
    return tool
  }

  mapButtons(button) {
    return button
  }

  async postData(e, ...args) {
    try {
      const { match, history, onRequestClose, location } = this.props
      await this.store.postData(match.url, this.store.item, ...args)
      if (this.store.item.id && typeof this.store.item.id !== 'object') {
        let p = match.url.substring(1).split('/')
        if (p[1] !== this.store.item.id.toString()) {
          const url =
            `/${p[0]}/${this.store.item.id}` + (location ? location.search : '')
          if (onRequestClose) {
            onRequestClose(null, this.store.item)
          } else if (history) {
            this.historyReplace(url)
          } else {
            await this.store.fetchItem(url)
          }
        }
      } else {
        this.goBack()
      }
    } catch (error) {
      this.onPostDataFail(error)
    }
  }

  historyReplace(url) {
    this.props.history.replace(url)
  }

  onPostDataFail(e) {
    console.log(e)
  }

  async deleteItem() {
    await this.store.deleteData(this.props.match.url)
    this.goBack()
  }

  goBack() {
    const { history, match, onRequestClose } = this.props
    if (onRequestClose) {
      onRequestClose(null, this.store.item)
    } else if (history) {
      if (history.length > 1) {
        history.goBack()
      } else {
        history.replace(match.url.split('/').slice(0, -1).join('/'))
      }
    } else {
      return false
    }
    return true
  }

  getEditableStructure() {
    return this.store.structure.filter(this.filterEdits).map(this.mapToReadOnly)
  }

  filterEdits(field) {
    const filters = this.editFilter || ['id', 'rec_date']
    return field.type !== 'BLOB' && !filters.includes(field.name)
  }

  mapToReadOnly({ read_only, ...field }) {
    return { ...field, read_only: read_only || this.store.isReadOnly() }
  }

  get editable_structure() {
    return this.getEditableStructure()
  }

  _toolsReactionExpression() {
    const { item, access, structure, is_read_only } = this.store
    const id = isFinite(item.id) ? +item.id : 0
    return id + access.length + structure.length + (is_read_only ? 0 : 1)
  }

  _toolsReactionEffect() {
    this.initButtons()
  }

  _render_index = 0
  render() {
    const { item, visible_structure, hide_structure, access } = this.store
    this._render_index =
      visible_structure.length +
      hide_structure.length +
      access.length +
      (this.store.isReadOnly() ? 0 : 1)
    const form = this.renderForm(
      this.tools,
      this.renderFields(item, visible_structure, hide_structure),
      this.buttons,
      this.absoluteForm(),
    )
    return (
      <ToolsReaction
        expression={this._toolsReactionExpression.bind(this)}
        effect={this._toolsReactionEffect.bind(this)}
      >
        {form}
      </ToolsReaction>
    )
  }
}
