import isNull from 'lodash/isNull'
import isString from 'lodash/isString'
import { observer, PropTypes as MobxTypes } from 'mobx-react'
import numeral from 'numeral'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronLeft } from 'react-icons/lib/fa'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import FormBase from '../../common/components/FormBase'
import { getModelValue } from '../../common/utils'
import BranchPopup from '../ui/BranchPopup'
import { EnumSelect, EnumText } from '../ui/EnumSelect'
import FormCheckbox from '../ui/FormCheckbox'
import { FormDatePicker } from '../ui/FormDatePicker'
import { AutoButton, FileInput, FormInput, FormLabel } from '../ui/FormInput'
import FormPopup from '../ui/FormPopup'
import FormTimePicker from '../ui/FormTimePicker'
import FormParamViewer from './form-params/FormParamViewer'
import PagePrintableDropDownButton from './PagePrintableDropDownButton'

export default class DataFormBase extends FormBase {
  constructor(props) {
    super(props)
    this.renderHideFields = this.renderHideFields.bind(this)
    this.renderVisibleFields = this.renderVisibleFields.bind(this)
  }

  initButtons(no_delete = false, no_save = false, back_to_list = true) {
    super.initButtons(no_delete, no_save)
    const { onRequestClose, mode } = this.props
    this.tools = [
      onRequestClose
        ? {
            icon: <FaChevronLeft />,
            action: onRequestClose,
            title: 'button.back',
          }
        : {
            icon: <FaChevronLeft />,
            action: this.goBack,
            title: back_to_list ? 'button.back_to_list' : 'button.back',
          },
    ]

    mode !== 'select' &&
      this.store.printable &&
      this.tools.push(
        <PagePrintableDropDownButton
          key='print'
          {...this.props}
          pageStore={this.store}
        />,
      )
  }

  mapTools(t, index) {
    if (React.isValidElement(t)) {
      return t
    } else if (t.link) {
      let title = this.tCommon(t.title)
      return (
        <Link
          key={index}
          to={t.link}
          title={title}
          className='btn btn-outline-primary'
        >
          {t.icon} {title}
        </Link>
      )
    } else if (t.action) {
      let title = this.tCommon(t.title)
      return (
        <Button
          key={index}
          title={title}
          color={'primary'}
          outline
          onClick={t.action}
        >
          {t.icon} {title}
        </Button>
      )
    }
  }

  renderPreForm() {
    return null
  }

  renderForm(tools, fields, buttons, absolute_view) {
    return (
      <Card className='data-base-card'>
        <CardHeader>
          <ButtonToolbar className='justify-content-between'>
            <ButtonGroup>{tools.map(this.mapTools)}</ButtonGroup>
            <ButtonGroup>{buttons.map(this.mapButtons)}</ButtonGroup>
          </ButtonToolbar>
        </CardHeader>
        <CardBody>
          {this.renderPreForm()}
          <Form className='base-form' onSubmit={e => e.preventDefault()}>
            {fields}
          </Form>
          {this.renderDeleteModal()}
          {absolute_view}
        </CardBody>
      </Card>
    )
  }

  renderDeleteModal(
    message = 'you_want_to_delete',
    confirm_text = 'delete',
    cancel_text = 'cancel',
  ) {
    return (
      <Modal
        key='deleteModal'
        isOpen={this.state.modalDelete}
        modalClassName='modal-danger'
        toggle={this.hideDeleteModal}
      >
        <ModalBody>
          <p className='text-danger'>{this.tCommon(`alert.${message}`)}</p>
        </ModalBody>
        <ModalFooter>
          <AutoButton onClick={this.hideDeleteModal}>
            {this.tCommon(`alert.${cancel_text}`)}
          </AutoButton>
          <AutoButton color='danger' onClick={this.deleteItem}>
            {this.tCommon(`alert.${confirm_text}`)}
          </AutoButton>
        </ModalFooter>
      </Modal>
    )
  }

  renderFieldItem(item, s, index, readOnly) {
    const { form_param_viewer = false, name, ...structure } = s
    if (!name) return
    if (form_param_viewer) {
      return (
        <FormParamViewer
          key={name}
          model={this.item_data}
          name={name}
          {...structure}
          readOnly={readOnly}
        />
      )
    }
    let label = s.type !== 'BOOLEAN' ? this.t(s.display_name) : ''
    let input = null
    if (readOnly && s.name.startsWith('file_')) {
      input = (
        <FileInput model={item} name={s.name} canClear={!s.required} readOnly />
      )
    } else if (readOnly && s.type !== 'DICT') {
      input = <FieldItemReadOnly item={item} structure={s} />
    } else if (s.name.startsWith('html')) {
      input = <FormInput model={item} name={s.name} type='wysiwyg' />
    } else if (s.type === 'BOOLEAN') {
      input = (
        <FormCheckbox
          model={item}
          name={s.name}
          title={this.t(s.display_name)}
        />
      )
    } else if (['DATE', 'DATETIME'].includes(s.type)) {
      input = <FormDatePicker model={item} name={s.name} />
    } else if (s.name.startsWith('file_')) {
      input = <FileInput model={item} name={s.name} canClear={!s.required} />
    } else if (['VARCHAR', 'TEXT'].includes(s.type)) {
      input = (
        <FormInput
          model={item}
          name={s.name}
          type={
            s.type === 'TEXT' || s.name.startsWith('long_')
              ? 'textarea'
              : 'text'
          }
        />
      )
    } else if (s.type === 'INTEGER' || s.type.startsWith('NUMERIC')) {
      input = <FormInput model={item} name={s.name} type='number' />
    } else if (s.type === 'DICT') {
      if (s.table === 'branchs') {
        input = (
          <BranchPopup
            model={item}
            name={s.name}
            structure={s}
            canClear={!s.required}
            readOnly={readOnly}
          />
        )
      } else {
        input = (
          <FormPopup
            {...s.props}
            model={item}
            name={s.name}
            structure={s}
            canClear={!s.required}
            readOnly={readOnly}
          />
        )
      }
    } else if (s.type === 'ENUM') {
      input = (
        <EnumSelect
          model={item}
          name={s.name}
          enumName={s.enum_name}
          disabled={readOnly}
          canClear={!s.required}
        />
      )
    } else if (s.type === 'INTERVAL') {
      input = <FormTimePicker model={item} name={s.name} />
    }
    return (
      <FormLabel key={s.name} label={label} readOnly={readOnly}>
        {input}
      </FormLabel>
    )
  }

  mapButtons(b) {
    return (
      <AutoButton key={b.title} color={b.color} onClick={b.action}>
        {b.icon} {this.tCommon(b.title)}
      </AutoButton>
    )
  }

  renderFields(item, visible_structure, hide_structure) {
    return (
      <>
        {super.renderFields(item, visible_structure)}
        {this.renderVisibleFields(item)}
        <HideFields readOnly={this.store.is_read_only}>
          {hide_structure?.length > 0 &&
            super.renderFields(item, hide_structure)}
          {this.renderHideFields(item)}
        </HideFields>
      </>
    )
  }
}

@observer
class FieldItemReadOnly extends React.Component {
  static propTypes = {
    item: MobxTypes.objectOrObservableObject,
    structure: MobxTypes.objectOrObservableObject,
  }

  renderEnum({ enumName, value, ...props }) {
    return (
      <span {...props}>
        <EnumText enumName={enumName} value={value} />
      </span>
    )
  }

  render() {
    const { item, structure } = this.props
    const { type, name } = structure
    if (type === 'ENUM')
      return (
        <Input
          enumName={structure.enum_name}
          value={getModelValue(item, name)}
          tag={this.renderEnum.bind(this)}
          readOnly
        />
      )
    if (type.startsWith('NUMERIC'))
      return <Input value={getModelValue(item, name)} tag={Numeric} readOnly />
    if (type.startsWith('file_'))
      return <FileInput model={item} name={name} readOnly />
    return <FormInput model={item} name={name} readOnly />
  }
}

const Numeric = ({ value, ...props }) => {
  value = isString(value) ? parseFloat(value) : value
  if (!isNull(value) && isFinite(value)) {
    const l = `${value}`.split(/[,.]/)[1]?.length || 0
    value = numeral(value).format(
      l > 0 ? '0,0[.]' + ''.padStart(l, '0') : '0,0',
    )
  }
  return <span {...props}>{value}</span>
}

const HideFields = ({ readOnly, children }) => {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation('documents')

  const toggle = () => setOpen(prevState => !prevState)

  if (children.filter(Boolean).length === 0) return null
  if (readOnly) return children
  return (
    <>
      <Collapse isOpen={open} autoFocus={false}>
        {children}
      </Collapse>
      <FormLabel label={''}>
        <AutoButton onClick={toggle}>{open ? t('hide') : t('show')}</AutoButton>
      </FormLabel>
    </>
  )
}
