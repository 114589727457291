import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import DynamicImport from '../../components/DynamicImport'
import DataForm from '../../pages/DataForm'

const CreateModal = ({
  isOpen,
  match,
  page,
  parentId,
  toggle,
  defaultData,
}) => (
  <Modal autoFocus={false} isOpen={isOpen} toggle={toggle} size='xl'>
    <ModalBody>
      <DynamicImport
        page={page}
        match={match}
        location={{ search: '?p=' + parentId }}
        onRequestClose={toggle}
        fallback={DataForm}
        defaultData={defaultData}
      />
    </ModalBody>
  </Modal>
)

export default CreateModal
