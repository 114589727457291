import { useEffect, useState } from 'react'

export default function useDocumentDynamicEditor(
  editor,
  fallback,
  importEditor,
) {
  const [{ Editor, editor: _editor, loading = false }, setState] = useState({})

  useEffect(() => {
    if (!editor) return
    let active = true
    setState(s => ({ ...s, loading: true }))
    importEditor(editor)
      .then(m => {
        active && setState({ Editor: m.default || fallback, editor })
      })
      .catch(e => {
        console.warn(e)
        active && setState({ Editor: fallback, editor })
      })
    return () => {
      active = false
    }
  }, [editor, fallback, importEditor])

  return Editor && editor === _editor ? { Editor } : { loading }
}
