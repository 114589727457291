import { useEffect, useState } from 'react'

/**
 * @param {string} editor
 * @param fallback
 * @param importEditor
 * @returns {{Component}|{loading: boolean}}
 */
export default function useDocumentDynamicTable(
  editor,
  fallback,
  importEditor,
) {
  const [{ Component, editor: _editor, loading = false }, setState] = useState(
    {},
  )

  useEffect(() => {
    if (!editor) {
      setState(s => (s.loading ? {} : s))
      return
    }
    let active = true
    setState(s => ({ ...s, loading: true }))
    importEditor(editor)
      .then(m => {
        active && setState({ Component: m.default || fallback, editor })
      })
      .catch(e => {
        console.warn(e)
        active && setState({ Component: fallback, editor })
      })
    return () => {
      active = false
    }
  }, [editor, fallback, importEditor])

  return !editor && fallback
    ? { Component: fallback }
    : editor === _editor && Component
    ? { Component }
    : { loading }
}
