import moment from 'moment'
import AppStore from '../store/AppStore'
import requester from './requester'
import { isWeb } from './settings'
import getRealm from './sync/model'
import { saveTableData } from './sync/utils'
import { guid } from './utils'

let iConnected = false

const reduceStructure = (ks, s) => {
  ks[s.name] = s
  return ks
}

const parseFilterParams = (params, structure) => {
  const fp = Object.keys(params).filter(onlyFilterParams).map(mapFilterParams)
  const fs = structure.reduce(reduceStructureTypes, {})
  return { filter_params: fp.filter(f => fs[f]), filter_structure: fs }
}

const onlyFilterParams = p => {
  return p.startsWith('filter[') && p.endsWith(']')
}

const mapFilterParams = p => {
  return p.slice(7, -1)
}

const reduceStructureTypes = (ks, s) => {
  ks[s.name] = s.type
  return ks
}

const asyncWrite = (realm, callback) => {
  return new Promise((resolve, reject) =>
    realm.write((...args) => {
      try {
        return resolve(callback(...args))
      } catch (e) {
        return reject(e)
      }
    }),
  )
}

const isItemFound = (item, table, param) => {
  if (typeof item === 'undefined')
    throw new Error(`Row in "${table}" with id ${param} not found`)
}

const sort = (a, b) =>
  [a, b].sort().indexOf(a) === 1 ? 1 : [b, a].sort().indexOf(b) === 1 ? -1 : 0

const localDB = {
  setConnected(status) {
    iConnected = status
  },
  get hasConnection() {
    return !!(isWeb() || iConnected)
  },
  async get(
    pathname,
    params = {},
    order_by = 'rec_date desc',
    silent = false,
    offline = false,
    offline_filter,
  ) {
    let [table, param] = pathname.substring(1).split('/')
    let data = null
    let ret = null
    try {
      const realm = await this.isSupported()

      this.checkTable(table, realm)

      let structure = realm
        .objects('TableScheme')
        .filtered('table=$0', table)[0]
      if (!structure) structure = []
      else structure = structure.jsonData

      let access = realm.objects('TableAccess').filtered('table=$0', table)[0]
      if (!access) access = []
      else access = access.jsonData

      if (!param) {
        let list = realm.objects('TableData').filtered('table=$0', table)
        if (this.hasConnection || offline) {
          list = list.filtered('is_dirty=$0', true)
        }
        let { filter_params, filter_structure } = parseFilterParams(
          params,
          structure,
        )
        if (filter_params.length > 0) {
          if (filter_params.includes('start_from')) {
            list = list.filtered(
              'rec_date>=$0',
              moment(params['filter[start_from]']).startOf('day').toDate(),
            )
            filter_params.splice(filter_params.indexOf('start_from'), 1)
          }
          if (filter_params.includes('end_to')) {
            list = list.filtered(
              'rec_date<$0',
              moment(params['filter[end_to]'])
                .startOf('day')
                .add(1, 'day')
                .toDate(),
            )
            filter_params.splice(filter_params.indexOf('end_to'), 1)
          }
          if (filter_params.includes('rec_user')) {
            list = list.filtered('rec_user=$0', params['filter[rec_user]'])
            filter_params.splice(filter_params.indexOf('rec_user'), 1)
          }
        }
        let order_params = []
        if (order_by && typeof order_by === 'string') {
          order_params = order_by.split(',').map(o => o.trim().split(' '))
          while (order_params.length > 0) {
            const [key, order] = order_params[0]
            let has
            switch (key) {
              case 'rec_date':
                list = list.sorted('rec_date', order === 'desc')
                has = true
                break
              case 'rec_user':
                list = list.sorted('rec_user', order === 'desc')
                has = true
                break
              case 'id':
                list = list.sorted([
                  ['id', order === 'desc'],
                  ['local_id', order === 'desc'],
                ])
                has = true
                break
              default:
                has = false
            }
            if (!has) break
            order_params.shift()
          }
        }

        list = list.map(item => item.jsonData)
        await 0 //this line required to unlock threads

        if (filter_params.length > 0) {
          list = list.filter(item => {
            let is_match = true
            for (const fp of filter_params) {
              const ff = params[`filter[${fp}]`]
              const fs = filter_structure[fp]
              if (!ff || !item[fp]) is_match = !ff && !item[fp]
              else if (fs.startsWith('DATE'))
                is_match = moment(ff).isSame(moment(item[fp]))
              else if (fs.startsWith('VARCHAR'))
                is_match = item[fp].toLowerCase().trim().includes(ff)
              else if (
                ['INTEGER', 'DICT'].includes(fs) ||
                fs.startsWith('NUMERIC')
              )
                is_match = [typeof ff, typeof item[fp]].includes('string')
                  ? ff === item[fp]
                  : ff - item[fp] === 0
              else is_match = ff === item[fp] || (!ff && !item[fp])
              if (!is_match) return false
            }
            if (is_match && offline_filter) return offline_filter(item)
            return is_match
          })
        } else if (offline_filter) {
          list = list.filter(offline_filter)
        }
        console.log('list length: ' + list.length)
        if (typeof order_by === 'function') {
          list.sort(order_by)
        } else if (order_params.length > 0) {
          let sk = structure.reduce(reduceStructure, {})
          list.sort((a, b) => {
            try {
              return this.applyOrder(a, b, sk, order_params)
            } catch (e) {
              console.log(e && e.message ? e.message : e)
              return 0
            }
          })
        }
        console.log('Got data from localDB', pathname, params)
        ret = this.applyPaging(
          { list, access, structure },
          this.hasConnection ? null : params.page,
        )
      } else {
        let item = null
        if (param !== 'add') {
          item = realm
            .objects('TableData')
            .filtered(
              'table=$0 AND (id=$1 OR local_id=$2)',
              table,
              param,
              param,
            )[0]
          isItemFound(item, table, param)
          if (item) item = item.jsonData
        } else {
          item = {}
          for (let a of structure) {
            item[a.name] = a.default || null
          }
        }
        console.log('Got data from localDB', pathname, params)
        for (let s of structure) {
          if (
            s.type === 'DICT' &&
            item[s.name] &&
            !item[s.name.split('_')[0]]
          ) {
            const rel_id = `${item[s.name]}`
            let relItem = realm
              .objects('TableData')
              .filtered(
                'table=$0 AND (id=$1 OR local_id=$2)',
                s.table,
                rel_id,
                rel_id,
              )[0]
            item[s.name.split('_')[0]] = relItem ? relItem.jsonData : null
            if (!relItem) item[s.name] = null
          }
        }
        ret = { item, access, structure }
      }
    } catch (e) {
      console.log(e && e.message ? e.message : e)
    }
    if (!offline && this.hasConnection && (!param || !ret)) {
      let resp = await requester.get(pathname, params, silent)
      data = resp.data
      console.log('Got data from Server', pathname, params)
    }
    if (ret) {
      if (!param && data) {
        await this.saveData(table, data, this.hasConnection)
        let local_ids = ret.list.map(l => l.id)
        data.list = [
          ...ret.list,
          ...data.list.filter(s => !local_ids.includes(s.id)),
        ]
      } else {
        data = ret
      }
    } else if (data) {
      await this.saveData(table, data, this.hasConnection)
    }
    if (!data && !silent) {
      AppStore.showWarning('Требуется подключение к интернету')
    }
    return data
  },
  checkAccess(item, access, structure) {
    if (item.id && access.indexOf('edit') === -1) {
      throw Error('Нет доступа')
    }
    if (!item.id && access.indexOf('create') === -1) {
      throw Error('Нет доступа')
    }
    if (!this.local(structure)) {
      throw Error('Not versioned')
    }
  },
  async post(pathname, item) {
    const [table, param] = pathname.substring(1).split('/')
    let data = null
    const local_id = item.id
    if (this.hasConnection) {
      if (param && !isFinite(param)) {
        delete item.id
      }
      let resp = await requester.post(pathname, item)
      data = resp.data
      console.log('Saved data to Server', pathname, data && data.id)
    }
    try {
      const realm = await this.isSupported()

      this.checkTable(table, realm)

      let structure = realm
        .objects('TableScheme')
        .filtered('table=$0', table)[0]
      if (!structure) structure = []
      else structure = structure.jsonData

      let access = realm.objects('TableAccess').filtered('table=$0', table)[0]
      if (!access) access = []
      else access = access.jsonData

      if (this.hasConnection) {
        if (local_id && local_id !== 'add') {
          item.id = data ? data.id : local_id
          try {
            let { data: resp } = await requester.get(`/${table}/${item.id}`)
            let local_items = realm
              .objects('TableData')
              .filtered(
                'table=$0 AND (id=$1 OR local_id=$1)',
                table,
                `${local_id}`,
              )
            console.log(
              `Local row with id ${local_id} in table ${table} was ${
                local_items.length === 0 ? 'not ' : ''
              }found`,
            )
            await asyncWrite(realm, () => {
              if (local_items.length > 0) {
                for (let local_item of local_items.slice())
                  realm.delete(local_item)
              }
              saveTableData(
                table,
                {
                  ...resp.item,
                  remote_id: resp.item.remote_id || local_id,
                },
                realm,
                true,
              )
              console.log(
                `Remote row with id ${resp.item.id} and remote_id ${
                  resp.item.remote_id || local_id
                } ` + `in table ${table} saved`,
              )
            })
          } catch (e) {
            console.log(e && e.message ? e.message : e)
            let local_item = realm
              .objects('TableData')
              .filtered('table=$0 AND local_id=$1', table, `${local_id}`)[0]
            local_item &&
              realm.write(() => {
                realm.delete(local_item)
              })
          }
        }
      } else {
        this.checkAccess(item, access, structure)
        item.id = item.id || guid()
        item.rec_user = item.rec_user || AppStore.user_info.username
        item.version = (item.version || 0) + 1
        let local_item = realm
          .objects('TableData')
          .filtered(
            'table=$0 AND (id=$1 OR local_id=$1)',
            table,
            `${item.id}`,
          )[0]
        await new Promise((resolve, reject) => {
          realm.write(() => {
            try {
              if (local_item) {
                local_item.is_dirty = true
                local_item.rec_date = moment(
                  item.rec_date || new Date(),
                ).toDate()
                local_item.rec_user = item.rec_user
                local_item.version = item.version
                local_item.data = JSON.stringify(item)
              } else {
                saveTableData(
                  table,
                  {
                    ...item,
                    id: null,
                    remote_id: item.id,
                  },
                  realm,
                  true,
                  true,
                )
              }
              resolve()
            } catch (e) {
              reject(e)
            }
          })
        })
        console.log(
          `Row with id ${item.id} in "${table}" saved data to localDB`,
          { ...item },
        )
        data = item
      }
    } catch (e) {
      console.log(e && e.message ? e.message : e)
      if (!data) throw e
    }
    return data
  },
  async delete(pathname) {
    let [table, param] = pathname.substring(1).split('/')
    if (!isNaN(param)) {
      return await requester.delete(pathname)
    }
    const realm = await this.isSupported()
    let local_item = realm
      .objects('TableData')
      .filtered('table=$0 AND local_id=$1', table, param)[0]
    local_item &&
      realm.write(() => {
        realm.delete(local_item)
      })
  },
  async saveData(table, resp, only_data = false) {
    if (!resp) return
    try {
      const realm = await this.isSupported()

      this.checkTable(table, realm)

      if (!this.local(resp.structure)) return
      realm.write(() => {
        if (!only_data) {
          realm.create(
            'TableScheme',
            { table, data: JSON.stringify(resp.structure) },
            true,
          )
          realm.create(
            'TableAccess',
            { table, data: JSON.stringify(resp.access) },
            true,
          )
        }
        if (resp.list) {
          for (let item of resp.list) {
            saveTableData(table, item, realm)
          }
        } else if (resp.item && resp.item.id) {
          saveTableData(table, resp.item, realm, true)
        }
      })
      console.log('Saved data to localDB')
    } catch (e) {
      console.log(e && e.message ? e.message : e)
    }
  },
  applyPaging(data, page) {
    data.count = data.list.length
    if (!page) return data
    const index = (page - 1) * 40
    data.list = data.list.slice(index, index + 40)
    return data
  },
  compareItems(left, right, structure_keyed, order_by) {
    let s = structure_keyed[order_by[0]] || {}
    if (!s.type) {
      return 0
    }
    let a = left[order_by[0]]
    let b = right[order_by[0]]
    let order = 0
    if (s.type.startsWith('VARCHAR') || s.type === 'TEXT') {
      if (typeof a === 'string') {
        order = a.localeCompare(b)
      } else if (typeof b === 'string') {
        order = b.localeCompare(a)
      } else {
        order = sort(a, b)
      }
    } else if (s.type.startsWith('NUMERIC') || s.type === 'INTEGER') {
      a = !isNaN(a) ? parseFloat(a) : null
      b = !isNaN(b) ? parseFloat(b) : null
      order = a && b ? (a > b ? 1 : a < b ? -1 : 0) : sort(a, b)
    } else if (['DATE', 'DATETIME'].includes(s.type)) {
      if (a && b) {
        a = moment(a)
        b = moment(b)
        order = a.isAfter(b) ? 1 : a.isBefore(b) ? -1 : 0
      } else order = sort(a, b)
    } else {
      try {
        order = sort(a, b)
      } catch (e) {
        console.warn(e)
      }
    }
    order = order_by[1] === 'desc' ? 0 - order : order
    return order
  },
  applyOrder(left, right, structure_keyed, orders) {
    let order = 0
    while (order === 0 && orders.length > 0) {
      order = this.compareItems(left, right, structure_keyed, orders.shift())
    }
    return order
  },
  local(structure) {
    return !isWeb() && structure.map(s => s.name).includes('version')
  },
  checkTable(table, realm) {
    if (realm.objects('Table').filtered('name=$0', table).length === 0) {
      throw new Error(`Table "${table}" is not versioned`)
    }
  },
  isSupported() {
    if (isWeb()) {
      throw new Error('LocalDB is not supported')
    }
    return getRealm()
  },
}
export default localDB
