import { action, computed, makeObservable, observable, observe } from 'mobx'
import moment from 'moment'
import xlsx from 'xlsx'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { autoFitColumns, printPdfReport } from '../common/utils'
import AppStore from './AppStore'
import { SelectionStore } from './SelectionStore'
import { SellStore } from './SellStore'
import StockStore from './StockStore'

const SALE_STATUS_ORDERS = {
  new: 0,
  archive: 1,
}

export class SaleStore extends SelectionStore {
  @observable input_column = 'quantity'
  @observable pay_debt = null
  @observable item_debt = 0
  @observable prices_index = -1
  @observable settings = {}
  @observable excel_import_account_group_id = null
  @observable excel_import_account_group = null

  @computed get excel_import_params() {
    return { account_group_id: this.excel_import_account_group_id }
  }

  constructor() {
    super()
    makeObservable(this)
  }

  filterDefaults() {
    return {
      ...super.filterDefaults(),
      start_from: moment()
        .startOf('isoWeek')
        .startOf('month')
        .format('YYYY-MM-DD'),
    }
  }

  @action
  switchInput = () => {
    this.input_column = {
      quantity: 'return',
      return: 'defective',
      defective: 'quantity',
    }[this.input_column]
  }

  async localGet(...args) {
    if (args.length < 6) args.length = 6
    args[2] = SaleStore.orderSales
    args[5] = this.filterContractor
    return await super.localGet(...args)
  }

  filterContractor = item => {
    if (
      this.filters.has('contractor_search') &&
      this.filters.get('contractor_search')
    ) {
      if (!item.contractor) return false
      const search = this.filters.get('contractor_search').toLowerCase()
      return item.contractor.name.toLowerCase().startsWith(search)
    }
    return true
  }

  async fetchItem(pathname) {
    await this.loadSettings()
    const data = await localDB.get(pathname)
    if (data && data.item && data.item.data.products) {
      let templates = {}
      const stock = await SaleStore.getStock(data.item.branch_id)
      for (let p of data.item.data.products) {
        p.prices = []
        if (p.sub_products && p.sub_products.length > 0 && p.access_sub) {
          p.sub_products.forEach(item => (item.prices = []))
        } else {
          p.sub_products = []
        }
        p.count =
          (stock[p.nomenclature_id] ? stock[p.nomenclature_id].count || 0 : 0) +
          SellStore.countWithSubProduct(p)
        try {
          await SaleStore.getPrices(p, templates)
        } catch (e) {
          console.log(e)
        }
      }
    }
    this.setResult(data)
    return data
  }

  @action
  togglePrices = index => {
    this.prices_index = this.prices_index === index ? -1 : index
  }

  @computed
  get products_with_sub() {
    const ret_products = []
    const products = this.item.data ? this.item.data.products : []
    for (const p of products) {
      ret_products.push(p)
      if (p.access_sub) p.sub_products?.forEach(item => ret_products.push(item))
    }
    return ret_products
  }

  async loadSettings() {
    try {
      this.setSettings(await SellStore.getSettings())
    } catch {}
  }

  @action setSettings = settings => {
    this.settings = settings
  }

  static async getStock(branch_id) {
    if (!branch_id)
      branch_id = AppStore.user_info ? AppStore.user_info.branch_id : 0
    let stock = await localDB.get('/stock', { 'filter[branch_id]': branch_id })
    const stores = await StockStore.getOfflineStores()
    if (!stock) stock = { list: [] }
    for (let item of stock.list) {
      if (!item.branch_id || !item.nomenclature_id) continue
      if (stores[item.nomenclature_id]) {
        stores[item.nomenclature_id].count += parseFloat(item.count || '0')
      } else {
        stores[item.nomenclature_id] = { count: parseFloat(item.count || '0') }
      }
    }
    return stores
  }

  static async getPrices(p, templates, select_default = false) {
    let n_data = await localDB.get('/nomenclatures/' + p.nomenclature_id)
    if (!n_data) n_data = { item: null }
    p.nomenclature = n_data.item
    if (!p.nomenclature || !p.nomenclature.data.templates) return

    if (select_default && p.access_sub === undefined) {
      p.access_sub = SellStore.accessSubProduct(
        p.nomenclature.data.sub_branches || [],
      )
      if (p.access_sub) {
        let sp = p.nomenclature.data.sub_nomenclature || []
        p.sub_products = sp.map(item => {
          item.is_sub = true
          return SaleStore.createProduct(item.nomenclature_id, item)
        })
      } else {
        p.sub_products = []
      }
    }

    let prices = []
    for (const tid of p.nomenclature.data.templates) {
      if (typeof templates[tid] !== 'undefined') {
        prices.push(...templates[tid])
        continue
      }
      let t = await localDB.get('/templates/' + tid)
      t = t ? t.item : null
      if (t && t.temp_type === 'price' && !t.data.decider && t.data.prices) {
        templates[tid] = t.data.prices
        prices.push(...templates[tid])
      } else {
        templates[tid] = []
      }
    }
    if (prices.length > 0) {
      const sale_prices = SellStore.reducePrices(prices)
      if (sale_prices.length === 0) {
        p.prices = prices
        p.sub_products.forEach(item => (item.prices = prices))
        return
      }
      let min_price = SellStore.minPrice(sale_prices)
      p.min_price = min_price
      p.prices = sale_prices
      p.sub_products.forEach(item => {
        item.prices = sale_prices
        item.min_price = min_price
      })
      if (!select_default) return
      const standard_price = sale_prices.find(
        p => p.name === 'standart' || p.name === 'standard',
      )
      let price, price_type
      if (standard_price) {
        price = parseFloat(parseFloat(standard_price.price).toFixed(4))
        price_type = standard_price.name
      } else {
        price = parseFloat(parseFloat(sale_prices[0].price).toFixed(4))
        price_type = sale_prices[0].name
      }
      p.price = price
      p.data.price_type = price_type
      p.sub_products.forEach(item => {
        item.price = price
        item.data.price_type = price_type
      })
    }
  }

  addProducts = async stock => {
    let templates = {}
    let nids = {}
    let new_items = []
    let products = this.item.data ? this.item.data.products : []
    for (const { nomenclature_id, count } of stock) {
      if (nids[nomenclature_id]) continue
      const i = products.findIndex(p => p.nomenclature_id === nomenclature_id)
      if (i > -1) {
        nids[nomenclature_id] = true
        continue
      }
      let p = SaleStore.createProduct(nomenclature_id, { count })
      try {
        await SaleStore.getPrices(p, templates, true)
      } catch (e) {
        console.log(e)
      }
      new_items.push(p)
      nids[nomenclature_id] = true
    }
    this.addNewProducts(new_items)
  }

  @action
  addNewProducts(new_items) {
    this.item.data && this.item.data.products.push(...new_items)
  }

  static createProduct(nomenclature_id, rest_item) {
    let product = {
      nomenclature_id,
      nomenclature: null,
      quantity: null,
      return: null,
      defective: null,
      price: null,
      prices: [],
      data: { price_type: null },
      history: [],
      sub_products: [],
    }
    if (rest_item) product = { ...product, ...rest_item }

    return product
  }

  addQrProduct = async (identity, quantity, product) => {
    let p = SaleStore.createProduct(product.nomenclature_id)
    let products = this.item.data ? this.item.data.products : []
    const i = products.findIndex(
      p =>
        (p.nomenclature && p.nomenclature.identity === identity) ||
        p.nomenclature_id === product.nomenclature_id,
    )
    const col = this.input_column
    if (i > -1) {
      let quantity = parseFloat(products[i][col] || '0') + parseFloat(quantity)
      p = {
        ...products[i],
        [col]: quantity,
      }
    } else {
      p[col] = quantity
    }
    try {
      let templates = {}
      await SaleStore.getPrices(p, templates, i === -1)
    } catch (e) {
      console.log(e)
    }
    this.addNewQRProduct(p, i)
  }

  @action
  addNewQRProduct(item, i) {
    if (this.item.data) {
      if (i > -1) this.item.data.products[i] = item
      else this.item.data.products.push(item)
    }
  }

  @action
  setPrice(product) {
    const {
      prices,
      data: { price_type },
    } = product
    let price = prices.find(p => p.name === price_type)
    if (price) product.price = parseFloat(price.price)
    if (!this.isValidNumber(product.price)) product.price = null
  }

  @action
  removeProduct(p) {
    const { item } = this
    if (item.data && p.history.length === 0) {
      item.data.products.remove(p)
    } else if (item.data && p.history.length > 0) {
      p.quantity = p.return = p.defective = null
    }
  }

  get canScan() {
    const { item, access } = this
    return access.includes('edit') && item.sale_status === 'new' && item.data
  }

  static orderSales(i1, i2) {
    let o =
      SALE_STATUS_ORDERS[i1.sale_status] - SALE_STATUS_ORDERS[i2.sale_status]
    if (o === 0) {
      const r1 = moment(i1.rec_date)
      const r2 = moment(i2.rec_date)
      o = r1.isAfter(r2) ? -1 : r1.isBefore(r2) ? 1 : 0
    }
    return o
  }

  itemTotal = item => {
    return item.sale_status === 'archive'
      ? parseFloat(item.total_amount || '0')
      : SellStore.countTotal(
          item.data ? item.data.products : [],
          this.settings,
          item.data?.discount_amount || 0,
          item.data?.tax_amount || 0,
        )
  }

  setSingle(item) {
    const { user_info: ui } = AppStore
    if (
      !this.isReadOnly() &&
      (!ui || item.branch_id !== ui.branch_id || item.sale_status === 'archive')
    ) {
      this.setAccess(
        this.access.includes('delete') ? ['view', 'delete'] : ['view'],
      )
      this.setStructure(this.structure.map(this.mapStructureReadOnly))
    }
    const total = this.itemTotal(item)
    this.item_debt = total - (item.pay_amount || 0)
    super.setSingle(item)
    this.loadDebts()
    if (!this.contractorDisposer)
      this.contractorDisposer = observe(
        this.item,
        'contractor_id',
        this.onSelectContractor,
      )
  }

  @computed
  get total_amount() {
    return this.itemTotal(this.item)
  }

  @computed
  get pay_total() {
    return this.total_amount
  }

  @computed
  get pay_diff() {
    let total_amount = this.total_amount
    let pay_debt = this.pay_debt
    total_amount = parseFloat(total_amount || 0)
    pay_debt = parseFloat(pay_debt || 0)
    return (this.item.pay_amount || 0) - (total_amount + pay_debt)
  }

  clearItem() {
    this.contractorDisposer && this.contractorDisposer()
    super.clearItem()
    this.input_column = 'quantity'
    this.pay_debt = null
    this.prices_index = -1
  }

  @action
  onSelectContractor = () => {
    this.loadDebts()
  }

  loadDebts = () => {
    if (this.item.contractor_id)
      SellStore.getDebts([this.item.contractor_id]).then(this.setDebt)
    else this.setDebt({})
  }

  @action
  setDebt = debts => {
    if (this.item.contractor_id)
      this.pay_debt = (debts[this.item.contractor_id] || 0) - this.item_debt
    else this.pay_debt = 0
  }

  validateQuantity() {
    if (!this.isValidNumber(this.item.pay_amount))
      throw new Error('Укажите сумму оплаты!')
    for (const p of this.item.data.products)
      if (![p.quantity, p.return, p.defective].every(this.isValidQuantity))
        throw new Error('Проверьте правильность количества')
  }

  isValidNumber = num =>
    typeof num !== 'undefined' &&
    num !== null &&
    num !== '' &&
    isFinite(num - 0)
  isValidQuantity = num =>
    typeof num === 'undefined' ||
    num === null ||
    num === '' ||
    isFinite(num - 0)

  isNaN = amount =>
    amount
      ? (typeof amount === 'string' && amount.includes(',')) || isNaN(amount)
      : false

  async postData(pathname, item = this.item) {
    try {
      this.validateQuantity()
      return await super.postData(pathname, {
        ...item,
        pay_amount: item.pay_amount - 0,
        data: {
          ...item.data,
          history_pay_amount: SaleStore.mapPaymentsPost(item),
          products: item.data.products
            .slice()
            .map(item => SaleStore.mapProductsPost(item, false)),
        },
      })
    } catch (e) {
      this.catchPostData(e)
      throw e
    }
  }

  static mapPaymentsPost(item) {
    if (item.data.history_pay_amount.length === 0) {
      item.data.history_pay_amount.push({
        rec_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        pay_amount: item.pay_amount || 0,
        contractor_id: item.contractor_id,
      })
    }
    let pay_amount = item.data.history_pay_amount.reduce(
      SaleStore.reduceHistoryPayAmount,
      0,
    )
    const contractor_id =
      item.data.history_pay_amount[item.data.history_pay_amount.length - 1]
        .contractor_id
    if (
      pay_amount !== parseFloat(item.pay_amount || '0') ||
      item.contractor_id !== contractor_id
    ) {
      item.data.history_pay_amount.push({
        rec_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        pay_amount: parseFloat(item.pay_amount || '0') - pay_amount,
        contractor_id: item.contractor_id,
      })
    }
    return item.data.history_pay_amount
  }

  static reduceHistoryPayAmount(sum, h) {
    return sum + parseFloat(h.pay_amount || '0')
  }

  static mapProductsPost(item, is_sub = false) {
    if (item.history.length === 0) {
      item.history.push({
        rec_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        price: item.price || 0,
        min_price: item.min_price || 0,
        quantity: item.quantity || 0,
        return: item.return || 0,
        defective: item.defective || 0,
      })
    }
    let history = item.history.reduce(SaleStore.reduceHistory, {
      price: 0,
      min_price: 0,
      quantity: 0,
      return: 0,
      defective: 0,
    })
    let changed = false
    for (const k of Object.keys(history)) {
      if (history[k] !== parseFloat(item[k] || '0')) {
        changed = true
        break
      }
    }
    if (changed) {
      item.history.push({
        rec_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        price: parseFloat(item.price || '0') - history.price,
        min_price: parseFloat(item.min_price || '0') - history.min_price,
        quantity: parseFloat(item.quantity || '0') - history.quantity,
        return: parseFloat(item.return || '0') - history.return,
        defective: parseFloat(item.defective || '0') - history.defective,
      })
    }

    let product = {
      nomenclature_id: item.nomenclature_id,
      price: item.price || null,
      min_price: item.min_price || null,
      quantity: item.quantity || null,
      return: item.return || null,
      defective: item.defective || null,
      history: item.history,
      data: {
        price_type: item.data.price_type,
      },
    }

    if (is_sub) {
      product.nomenclature = item.nomenclature
      product.unit = item.unit
      product.is_sub = true
    } else {
      product = { ...product, ...item }
      product.prices = item.prices || []
      product.sid = item.sid || []
      product.sub_products = []
      if (item.access_sub)
        product.sub_products = item.sub_products.map(item =>
          SaleStore.mapProductsPost(item, true),
        )
    }
    return product
  }

  static reduceHistory(sum, h) {
    sum.price += parseFloat(h.price || '0')
    sum.min_price += parseFloat(h.min_price || '0')
    sum.quantity += parseFloat(h.quantity || '0')
    sum.return += parseFloat(h.return || '0')
    sum.defective += parseFloat(h.defective || '0')
    return sum
  }

  exportSalesExcel = () => {
    let header = {
      rec_date: 'Дата добавления',
      contractor: 'Контрагент',
      pay_amount: 'Полученная сумма',
      total_amount: 'Итого сумма',
      number_waybill: 'Номер накладной',
      branch: 'Отдел',
      sale_status: 'Статус продажи',
      inn: 'ИНН',
    }

    let items = this.items.map(item => {
      return {
        rec_date: item.rec_date,
        contractor: item.contractor.name,
        pay_amount: parseFloat(item.pay_amount).toFixed(2).replace('.', ','),
        total_amount: parseFloat(item.total_amount)
          .toFixed(2)
          .replace('.', ','),
        number_waybill: item.number_waybill,
        branch: item.branch.name,
        sale_status: item.sale_status === 'new' ? 'Новый' : 'Архив',
        inn: item.contractor.inn,
      }
    })

    items.unshift({ id: '' }, header)
    const worksheet = xlsx.utils.json_to_sheet(items, {
      skipHeader: true,
    })

    autoFitColumns(worksheet)

    const book = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(book, worksheet)

    xlsx.writeFile(book, `sales-${new Date().toISOString()}.xlsx`, {
      bookType: 'xlsx',
    })
  }

  get should_save_filter_keys() {
    return [
      ...super.should_save_filter_keys,
      'contractor_search',
      'user_id',
      'number_waybill',
    ]
  }

  get is_selected() {
    return this.selections.length > 0
  }

  downloadInvoice = async () => {
    const sale_ids = this.selections.map(item => item.id)
    const { data } = await requester.post('/sale/download/invoice', {
      sale_ids,
    })
    await printPdfReport(data.template, data.printable_data)
  }
}

const store = new SaleStore()
export default store
