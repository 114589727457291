import * as mobx from 'mobx'
import AccountingStore from './AccountingStore'
import AditecStore from './AditecStore'
import AgentDebtEditStore from './AgentDebtEditStore'
import AgentReportStore from './AgentReportStore'
import AgentSelectStore from './AgentSelectStore'
import AppStore from './AppStore'
import AuditStore from './AuditStore'
import BranchStore from './BranchStore'
import BUSelectStore from './BUSelectStore'
import CassetteStore from './CassetteStore'
import CdsStore from './CdsStore'
import ContractDebtStore from './ContractorDebtStore'
import ContractordocsStore from './ContractordocsStore'
import ContractorinvoiceStore from './ContractorinvoiceStore'
import ContractorStore from './ContractorStore'
import ProductInvoiceStore from './editors/ProductInvoiceStore'
import EditorStore from './EditorStore'
import EnumsStore from './EnumsStore'
import EquipmentStore from './EquipmentStore'
import FieldSupportStore from './FieldSupportStore'
import IdentityPrintStore from './IdentityPrintStore'
import ImageViewStore from './ImageViewStore'
import InvoiceReportStore from './InvoiceReportStore'
import InvoiceStore from './InvoiceStore'
import IotStore from './IotStore'
import JobStore from './JobStore'
import KpiReportStore from './KpiReportStore'
import KpiStore from './KpiStore'
import LibraryStore from './LibraryStore'
import LineStore from './LineStore'
import MenuStore from './MenuStore'
import MoveStore from './MoveStore'
import NomenclatureSaleHistoryStore from './NomenclatureSaleHistoryStore'
import NomenclatureStore from './NomenclatureStore'
import OrderPriceStore from './OrderPriceStore'
import OrderStore from './OrderStore'
import ProcessStore from './ProcessStore'
import ProductionsStore from './ProductionsStore'
import ProductionStore from './ProductionStore'
import ProductionTemplateStore from './ProductionTemplateStore'
import ProductStore from './ProductStore'
import ProductTraceStore from './ProductTraceStore'
import ReadyAgentStore from './ReadyAgentStore'
import ReadyAgentStoreOld from './ReadyAgentStoreOld'
import ReadyOrderStore from './ReadyOrderStore'
import ReceiptOrderStatusStore from './ReceiptOrderStatusStore'
import ReportSales from './report-sales/index'
import ReportStockStore from './ReportStockStore'
import RoleStore from './RoleStore'
import RouteStore from './RouteStore'
import SaleStore from './SaleStore'
import SalechartStore from './SalechartStore'
import ScreenStore from './ScreenStore'
import SelectionStore from './SelectionStore'
import SellStore from './SellStore'
import SettingsStore from './SettingsStore'
import StockStore from './StockStore'
import TaraStore from './TaraStore'
import TasksReportStore from './TasksReportStore'
import TaskStore from './TaskStore'
import TemplateStore from './TemplateStore'
import TraceProductionStore from './TraceProductionStore'
import TraceStore from './TraceStore'
import TransferStore from './TransferStore'
import UsersBranchesStore from './UsersBranchesStore'
import UserStore from './UserStore'
import ViewReportStore from './ViewReportStore'
import VehicleChecksStore from './VehicleChecksStore'
import SaleOrdersStore from './SaleOrdersStore'
import TrackerStore from './TrackerStore'

mobx.configure({ enforceActions: 'never' })

export default {
  AditecStore,
  AccountingStore,
  AgentDebtEditStore,
  AgentReportStore,
  AgentSelectStore,
  AppStore,
  AuditStore,
  BranchStore,
  BUSelectStore,
  CassetteStore,
  CdsStore,
  ContractDebtStore,
  ContractorStore,
  ContractordocsStore,
  ContractorinvoiceStore,
  EditorStore,
  EnumsStore,
  EquipmentStore,
  FieldSupportStore,
  IdentityPrintStore,
  ImageViewStore,
  InvoiceStore,
  ProductInvoiceStore,
  InvoiceReportStore,
  IotStore,
  JobStore,
  KpiStore,
  KpiReportStore,
  LibraryStore,
  LineStore,
  MenuStore,
  MoveStore,
  NomenclatureSaleHistoryStore,
  NomenclatureStore,
  OrderPriceStore,
  OrderStore,
  ProcessStore,
  ProductStore,
  ProductTraceStore,
  ProductionsStore,
  ProductionStore,
  ReadyAgentStore,
  ReadyAgentStoreOld,
  ReadyOrderStore,
  ReportStockStore,
  ReceiptOrderStatusStore,
  RoleStore,
  RouteStore,
  SaleStore,
  SalechartStore,
  ScreenStore,
  SelectionStore,
  StockStore,
  TemplateStore,
  TraceStore,
  TraceProductionStore,
  TransferStore,
  UsersBranchesStore,
  UserStore,
  ViewReportStore,
  SellStore,
  TaskStore,
  TasksReportStore,
  ReportSales,
  ProductionTemplateStore,
  TaraStore,
  SettingsStore,
  VehicleChecksStore,
  SaleOrdersStore,
  TrackerStore,
}
